import React from "react";
import Main from "../components/main";
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap";
import AddressAutoComplete from "../components/address-locator";
// import { useTranslation } from "react-i18next";
// import { Link } from "gatsby";
// import URLSearchParams from "@ungap/url-search-params";
// import root from "window-or-global";

export default function Index() {
  // useEffect(() => {
  //   let buildingCode, unitno;
  //   if (root?.location?.search) {
  //     const params = new URLSearchParams(root.location.search);
  //     buildingCode = params.get("building");
  //     unitno = params.get("unitno");
  //   }
  //   if (unitno && buildingCode) {
  //     root.location = `/provider?building=${buildingCode}&unitno=${unitno}`;
  //   }
  // }, [])

  return (
    <Main>
      <SEO title="Select your provider" />
      <Container fluid className="no-gutters">
        <Row className="align-items-center whole-screen">
          <Col xs={12} md={8} lg={5} className="bg-darkblue col-blue">
            <Container fluid className="text-md-left">
              <AddressAutoComplete />
            </Container>
          </Col>
          <Col xs={12} md={4} lg={7} className="h-100vh d-none d-md-block" style={{
            background: "url('/building.svg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }} />
        </Row>
      </Container>
    </Main>
  );
}