import React, { Fragment } from "react"
import root from "window-or-global"
import { Row, Col, Container } from "react-bootstrap"
import { useAsyncSetState } from "use-async-setstate"
import Formsy from "formsy-react"

import { debounce } from "./utils/index"
import { TextField } from "./bootstrap/text-field"
import { setFormData } from "./manager/form"
import {
  findBuildingQuery,
  getFindBuildingResult,
} from "../logic/address"

import { client } from "./main"

export default function AddressAutoComplete(props) {
  const [unitNo, setUnitNo] = useAsyncSetState(null)
  const [addresses, setAddresses] = useAsyncSetState([])
  const [selectedAddress, setSelectedAddress] = useAsyncSetState(null)
  const [addressError, setAddressError] = useAsyncSetState(false)
  const [unitError, setUnitError] = useAsyncSetState(null)

  const { highlight, init, inline, showLocator } = props
  const rowClassName = `
    vw-address-locator 
    ${inline ? "inline" : ""} 
    ${highlight ? "highlight" : ""} 
    ${showLocator ? "show-address-locator" : "hide-address-locator"} 
    ${init ? "vw-hide" : ""} 
    home ? "home" : ""
    magictime-300ms
  `

  const handleValidSubmit = async () => {
    if (!unitNo) {
      return setUnitError(true)
    }

    if (!selectedAddress) {
      return setAddressError(true)
    }

    await setFormData({
      building: selectedAddress,
      unit: unitNo,
    })

    root.location.href = `/provider?building=${selectedAddress.code}&unitno=${unitNo}`
  }

  const handleAddressChange = debounce(async address => {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: findBuildingQuery,
      variables: { address },
    })
    const result = getFindBuildingResult(response)

    if (!result.length) {
      return setAddressError(true)
    }

    await setAddressError(false)
    return setAddresses(result)
  }, 250)

  const handleAddressSelect = value => setSelectedAddress(value)

  return (
    <Formsy>
      <div className={rowClassName}>
        <Container fluid className="no-gutters">
          <Row>
            <Col className="align-items-center mb-4">
              <img
                src="/vostronet-logo-white.svg"
                alt="vostronet"
                className="img-vostronet"
              />
            </Col>
          </Row>
          <Row>
            <Col className="font-white fw-400 align-items-center mb-4">
              <img
                src="/location.svg"
                alt="location"
                className="img-location"
              />
              {"Search for your address"}
            </Col>
          </Row>
          <Row className={"justify-content-center position-relative mb-4"}>
            <Col xs={12} lg={8} className="mb-1 mb-md-0">
              <div className={`vw-field vw-street`}>
                {selectedAddress !== null ? (
                  <div
                    className="vw-al-selected-address-bg"
                    style={{ backgroundImage: `url('/map-marker-alt.svg')` }}
                  >
                    <div
                      className="vw-al-selected-address-label"
                      onClick={() => setSelectedAddress(null)}
                      style={{ backgroundImage: `url('/times-white.svg')` }}
                    >
                      {selectedAddress.name}
                    </div>
                  </div>
                ) : (
                  <input
                    name="address"
                    type="text"
                    placeholder="Address"
                    autoComplete="off"
                    onChange={async e =>
                      await handleAddressChange(e.target.value)
                    }
                    className={`vw-al-input-field ${
                      addressError ? "border-red" : ""
                    }`}
                  />
                )}
                {selectedAddress === null && addresses.length > 0 && (
                  <div className="vw-al-locations">
                    {addresses.map(address => (
                      <div
                        key={`address-${address.id}`}
                        className="vw-al-location"
                        style={{
                          backgroundImage: `url('/map-marker-alt.svg')`,
                        }}
                        onClick={() => handleAddressSelect(address)}
                      >
                        {address.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} lg={4} className="mb-1 mb-md-0">
            <div className={`vw-field vw-street`}>
              <input
                name="unitNo"
                value={unitNo}
                className={`unit-input ${
                  unitError ? "border-red" : ""
                }`}
                onChange={e => setUnitNo(e.target.value)}
                placeholder="Unit #"
              />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="vw-field">
              <button
                onClick={() => handleValidSubmit()}
                className={`btn btn-primary no-border-radius px-5 py-2 d-block mx-auto mx-md-0`}
              >
                {"Search"}
              </button>
            </div>
          </Row>
        </Container>
      </div>
    </Formsy>
  )
}
